import { Link } from 'routes/components/Shared'
import { G2_INTEGRATION_APP_URL } from 'routes/constants'
import { badgesG2Data } from './badgesG2Data'
import { BadgesG2Card } from './BadgesG2Card'
import clsx from 'clsx'
import classes from './BadgesG2.module.scss'

export const BadgesG2 = ({ className }: { className: string }) => {
  return (
    <div className={clsx(classes.wrapper, className)}>
      <ul className={classes.list}>
        {badgesG2Data.map((item, idx) => (
          <BadgesG2Card key={idx} item={item} />
        ))}
      </ul>
      <Link
        className={clsx('page__button', classes.button)}
        type='secondary'
        target='_blank'
        href={G2_INTEGRATION_APP_URL}
      >
        View Full Report
      </Link>
    </div>
  )
}
