import { useState, useEffect } from 'react'
import { TbBrandLinkedin } from 'react-icons/tb'
import { useIsClient } from 'usehooks-ts'
import { DOCS_LINK, LINKEDIN_URL, LINKS, REST_API_LINK } from 'routes/constants'
import { getAllCategories } from 'routes/Integrations/helpers'
import { CategoryCompactType } from 'routes/Integrations/helpers/types'
import { Container } from 'routes/components/Layout'
import { FooterCTA } from 'routes/components/Features/FooterCTA/FooterCTA'
import { Link, LogoBlock } from 'routes/components/Shared'
import NavLink from 'routes/components/Shared/Link/NavLink'
import clsx from 'clsx'
import classes from './Footer.module.scss'

const footerComparisonsIds = [
  { id: 'apideck', name: 'ApiDeck' },
  { id: 'merge', name: 'Merge.dev' },
  { id: 'nango', name: 'Nango' },
  { id: 'paragon', name: 'Paragon' },
  { id: 'prismatic', name: 'Prismatic' },
  { id: 'tray', name: 'Tray Embedded' },
  { id: 'workato', name: 'Workato' },
]

export function Footer({
  hideAdvertiseBlock = false,
}: {
  hideAdvertiseBlock?: boolean
}) {
  const [categories, setCategories] = useState<CategoryCompactType[]>([])

  const isClient = useIsClient()

  useEffect(() => {
    if (!isClient) return

    const fetchCategories = async () => {
      await getAllCategories()
        .then((res) => {
          setCategories(res)
        })
        .catch((e) => console.error(e))
    }

    const timer = setTimeout(() => {
      fetchCategories()
    }, 500)

    return () => clearTimeout(timer)
  }, [isClient])

  return (
    <>
      {!hideAdvertiseBlock && <FooterCTA />}

      <footer className={classes.footer}>
        <Container className={classes.wrapper}>
          <FooterContent categories={categories} />
          <FooterCredits />
        </Container>
      </footer>
    </>
  )
}

function FooterContent({ categories }: { categories: CategoryCompactType[] }) {
  return (
    <div className={classes.content}>
      <Link className={classes.logo} href='/'>
        <img
          width={53}
          height={32}
          loading='lazy'
          src='/images/logo--white.svg'
          alt='Integration App logo'
        />
      </Link>
      <div className={classes.nav}>
        <FooterCompanyList />
        <FooterProductList />
        <FooterComparisonsList />
      </div>
      {categories && <FooterCategoriesList categoriesData={categories} />}
    </div>
  )
}

function FooterCompanyList() {
  const links = [
    { title: 'About us', href: LINKS.ABOUT_US },
    { title: 'Blog', href: LINKS.ARTICLES },
    { title: 'How we are different', href: LINKS.HOW_WE_ARE_DIFFERENT },
    { title: 'Pricing', href: LINKS.PRICING },
    { title: 'Documentation', href: DOCS_LINK, isBlank: true },
    { title: 'API Reference', href: REST_API_LINK, isBlank: true },
  ]

  return (
    <nav className={clsx(classes.navColumn, classes.navColumn__company)}>
      <h3 className={classes.groupHead}>Company</h3>

      <ul className={classes.navList}>
        {links.map(({ title, href, isBlank }) => (
          <li key={title}>
            <NavLink
              href={href}
              target={isBlank ? '_blank' : '_self'}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

function FooterProductList() {
  const links = [
    { title: 'Universal Integrations', href: LINKS.UNIVERSAL_INTEGRATIONS },
    { title: 'Connector Builder', href: LINKS.CONNECTOR_BUILDER },
    { title: 'Integration UX', href: LINKS.INTEGRATION_UX },
    { title: 'How we use AI', href: LINKS.HOW_WE_USE_AI },
  ]

  return (
    <nav className={clsx(classes.navColumn, classes.navColumn__product)}>
      <h3 className={classes.groupHead}>Product</h3>

      <ul className={classes.navList}>
        {links.map(({ title, href }) => (
          <li key={title}>
            <NavLink
              href={href}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

function FooterComparisonsList() {
  return (
    <nav className={clsx(classes.navColumn, classes.navColumn__comparison)}>
      <h3 className={classes.groupHead}>Comparisons</h3>

      <ul className={classes.navList}>
        {footerComparisonsIds.map((item) => (
          <li key={item.id}>
            <NavLink
              href={`/alternatives/${item.id}`}
              className={classes.link}
              activeClassName={classes.link__active}
            >
              {`${item.name} alternative`}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

function FooterCategoriesList({
  categoriesData,
}: {
  categoriesData: CategoryCompactType[]
}) {
  function setCategoryUrl(value: string | null | undefined) {
    if (value) {
      return `${LINKS.INTEGRATIONS}?category=${value}`
    }
    return `${LINKS.INTEGRATIONS}`
  }
  return (
    <nav className={classes.categories}>
      <h3 className={classes.groupHead}>Supported Integrations</h3>

      <ul className={classes.categoriesList}>
        {categoriesData.map(({ count, category }) => (
          <li key={category}>
            <NavLink
              href={setCategoryUrl(category)}
              className={classes.categoriesLink}
            >
              {category ? category : 'All'}
              <span className={classes.categoriesNumber}>{`(${count})`}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

function FooterCredits() {
  return (
    <div className={classes.credits}>
      <div className={classes.creditsWrapper}>
        <LogoBlock className={classes.creditsLogo} />
        <div className={classes.creditsCopy}>
          © Copyrights {new Date().getFullYear()}{' '}
          <a href='https://integration.app'>Integration App</a>
        </div>
      </div>
      <FooterCreditsLinks />
    </div>
  )
}

function FooterCreditsLinks() {
  return (
    <nav>
      <ul className={classes.creditsLinks}>
        <li className={classes.creditsLinksItem}>
          <NavLink
            href={LINKS.PRIVACY_POLICY}
            className={classes.link}
            activeClassName={classes.link__active}
          >
            Privacy Policy
          </NavLink>
        </li>
        <li className={classes.creditsLinksItem}>
          <NavLink
            href={LINKS.TERMS_AND_CONDITIONS}
            className={classes.link}
            activeClassName={classes.link__active}
          >
            Terms and Conditions
          </NavLink>
        </li>
        <li className={classes.creditsLinksItem}>
          <a className={classes.link} href='mailto:hello@integration.app'>
            hello@integration.app
          </a>
        </li>
        <li className={classes.creditsLinksItem}>
          <a
            className={clsx(classes.link, classes.link__icon)}
            href={LINKEDIN_URL}
            target='_blank'
          >
            <TbBrandLinkedin className={classes.creditsLinksIcon} />
            Linkedin
          </a>
        </li>
      </ul>
    </nav>
  )
}
