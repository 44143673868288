import { StaticImageData } from 'next/image'
import BestSupportBadge from './2025-best-support.svg'
import HighPerformerBadge from './2025-high-performer.svg'
import EasiestBusinessBadge from './2025-easiest-to-do-business.svg'

export type BadgesG2DataType = {
  caption: string
  badge: StaticImageData
}

export const badgesG2Data: BadgesG2DataType[] = [
  {
    caption: 'G2 Winter 2025: Best Support',
    badge: BestSupportBadge,
  },
  {
    caption: 'G2 Winter 2025: High Performer',
    badge: HighPerformerBadge,
  },
  {
    caption: 'G2 Winter 2025: Easiest to Do Business with',
    badge: EasiestBusinessBadge,
  },
]
