import pluralize from 'pluralize'
import { ConnectorMethodImplementationType } from '@integration-app/sdk'
import { RelatedCollectionsType, IntegrationCollectionType } from './types'
import { API_ENDPOINTS, API_VERSION_QUERY } from 'routes/constants'
import {
  checkValidData,
  validateResponseStatusCode,
} from 'routes/Integrations/helpers'

export async function fetchIntegrationDataCollections(integrationKey: string) {
  try {
    // Fetch data from connectors endpoint, because we store data collections there
    // and we don't have separate endpoint for data collections
    const res = await fetch(
      `${API_ENDPOINTS.CONNECTORS}/${integrationKey}${API_ENDPOINTS.DATA_COLLECTIONS}`,
    )

    validateResponseStatusCode(
      res,
      `Integration Data Collection not found: ${integrationKey}`,
    )

    const dataCollections = await res.json()

    return {
      data_collections: dataCollections,
      data_collections_amount: dataCollections?.length,
    }
  } catch (e) {
    return {
      message: `Integration Data Collection for ${integrationKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

export async function fetchIntegrationSingleDataCollection(
  integrationKey: string,
  collectionKey: string,
) {
  const [collectionData, relatedCollectionsData] = await Promise.all([
    fetchIntegrationSingleDataCollectionBasicInfo(
      integrationKey,
      collectionKey,
    ),
    fetchIntegrationSingleDataCollectionRelatedDataCollections(
      integrationKey,
      collectionKey,
    ),
  ])

  return {
    ...checkValidData(collectionData),
    ...checkValidData(relatedCollectionsData),
  }
}

export async function fetchIntegrationSingleDataCollectionBasicInfo(
  integrationKey: string,
  collectionKey: string,
) {
  try {
    const res = await fetch(
      `${API_ENDPOINTS.CONNECTORS}/${integrationKey}/data/${collectionKey}${API_VERSION_QUERY}`,
    )

    validateResponseStatusCode(
      res,
      `Integration Data Collection not found: ${integrationKey}/${collectionKey}`,
    )

    return await res.json()
  } catch (e) {
    return {
      message: `Integration Data Collection for ${integrationKey}/${collectionKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

async function fetchIntegrationSingleDataCollectionRelatedDataCollections(
  integrationKey: string,
  collectionKey: string,
) {
  try {
    const res = await fetch(
      `${API_ENDPOINTS.CONNECTORS}/${integrationKey}/data/${collectionKey}${API_ENDPOINTS.RELATED_COLLECTIONS}`,
    )

    validateResponseStatusCode(
      res,
      `Integration Related Data Collections not found: ${integrationKey}/${collectionKey}`,
    )

    return await res.json()
  } catch (e) {
    return {
      message: `Integration Related Data Collections for ${integrationKey}/${collectionKey} not found`,
      type: 'bad_request',
      key: 'not_found',
    }
  }
}

export const collectionSupportsOperation = (
  collection: IntegrationCollectionType,
  operationKey: string,
) => {
  const operation = collection.methods?.[operationKey]

  return (
    operation &&
    operation.implementationType !==
      ConnectorMethodImplementationType.notSupported
  )
}

const makeSingularCollectionName = (collectionName: string) => {
  if (collectionName !== pluralize(collectionName, 1))
    return pluralize(collectionName, 1)

  const singularizedArray = collectionName.split(' ')
  const singularizedName = pluralize(singularizedArray[0], 1)
  const subString = singularizedArray.slice(1).join(' ')

  return `${singularizedName} ${subString}`
}

export const createCollectionOperationLinkString = (
  operationName: string,
  collectionName: string,
) => {
  const singularCollectionName = makeSingularCollectionName(collectionName)

  switch (operationName) {
    case 'List':
    case 'Search':
      return `${operationName} ${collectionName}`

    case 'Match':
      return `Find a Matching ${singularCollectionName}`

    case 'Find by Id':
      return `Find ${singularCollectionName} by Id`

    default:
      return `${operationName} ${singularCollectionName}`
  }
}

export const filterRelatedCollections = (
  relatedCollections: RelatedCollectionsType[],
  dataCollectionsKeys: string[],
  singleCollectionKey: string,
) => {
  const data = Object.values(
    relatedCollections.reduce((acc, obj) => ({ ...acc, [obj.key]: obj }), {}),
  )

  const filteredRelatedCollections = data
    .filter((item: RelatedCollectionsType) => {
      return (
        item.key !== singleCollectionKey &&
        dataCollectionsKeys.includes(item.key)
      )
    })
    .sort(
      (firstItem: RelatedCollectionsType, secondItem: RelatedCollectionsType) =>
        firstItem.key.localeCompare(secondItem.key),
    )

  return filteredRelatedCollections
}
