import { useRouter } from 'next/router'
import classes from './ArticleShare.module.css'

import SocialFacebook from './SocialFacebook.svg'
import SocialX from './SocialX.svg'
import SocialLinkedin from './SocialLinikedin.svg'

export function ArticleShare({ title }: { title: string }) {
  const { asPath } = useRouter()
  const getUrl = `${process.env.NEXT_PUBLIC_SITE_URI}${asPath}`

  function onClick(e, url) {
    if (window.innerWidth < 600) {
      return
    }

    e.preventDefault()
    e.stopPropagation()
    setTimeout(() => {
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      window.open(url, null, `popup,width=600,height=400`)
    })
  }

  const buttons = [
    {
      link: `https://www.facebook.com/sharer.php?u=${getUrl}`,
      title: 'Share Post to Facebook',
      iconSrc: SocialFacebook.src,
    },
    {
      link: `https://twitter.com/intent/tweet?url=${getUrl}&text=${title}`,
      title: 'Share Post to X',
      iconSrc: SocialX.src,
    },
    {
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${getUrl}`,
      title: 'Share Post to Linkedin',
      iconSrc: SocialLinkedin.src,
    },
  ]

  return (
    <div className={classes.wrapper}>
      <span>Share this post</span>

      <ul className={classes.shareList}>
        {buttons.map((button) => (
          <li key={button.link}>
            <a
              href={button.link}
              target={'_blank'}
              onClick={(e) => onClick(e, button.link)}
            >
              <img
                width={28}
                height={28}
                loading='lazy'
                src={button.iconSrc}
                alt={button.title}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
