import classes from './ArticleHeroImage.module.css'

export function ArticleHeroImage({ src, alt }: { src: string; alt?: string }) {
  if (!src) return null
  return (
    <div className={classes.heroImage}>
      <img fetchPriority='high' width={766} height={437} src={src} alt={alt} />
    </div>
  )
}
