import { useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { TbChevronDown, TbArrowRight } from 'react-icons/tb'
import { BookCalendlyButton, Link } from 'routes/components/Shared'
import { headerNavigationData } from './headerNavigationData'
import { HeaderNavigationDataType } from './headerNavigationData'
import Icon from './icon-solutions.svg'
import clsx from 'clsx'
import classes from './HeaderStyles.module.scss'

export const HeaderNavigation = () => {
  const [subMenuActiveIndex, setSubMenuActiveIndex] = useState<number | null>(
    null,
  )
  const isTablet = useMediaQuery('(max-width: 1023px)')

  const toggleSubMenu = (index: number) => {
    if (!isTablet) return
    setSubMenuActiveIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <nav className={classes.navigation}>
      <ul className={classes.menuList}>
        {headerNavigationData.map((item, idx) => (
          <NavigationItem
            key={idx}
            item={item}
            onClick={() => toggleSubMenu(idx)}
            isActive={subMenuActiveIndex === idx}
          />
        ))}
      </ul>
    </nav>
  )
}

const NavigationItem = ({
  item,
  onClick,
  isActive,
}: {
  item: HeaderNavigationDataType
  onClick: () => void
  isActive: boolean
}) => {
  const { name, href, isBlank } = item

  return (
    <>
      {href ? (
        <li className={classes.menuItem}>
          <Link
            href={href}
            target={isBlank ? '_blank' : '_self'}
            className={classes.menuLink}
          >
            {name}
          </Link>
        </li>
      ) : (
        <li
          className={clsx(
            classes.menuItem,
            classes.menuItem__dropdown,
            isActive && classes.menuItem__open,
          )}
        >
          <span className={classes.menuTitle} onClick={onClick}>
            <TbChevronDown
              className={clsx(classes.icon, classes.icon__chevron)}
            />
            {name}
          </span>
          {name === 'Solutions' ? (
            <SolutionsSubmenu {...item} />
          ) : (
            <DefaultSubmenu {...item} />
          )}
        </li>
      )}
    </>
  )
}

const SolutionsSubmenu = ({ ...props }: HeaderNavigationDataType) => {
  const { name, columns, type } = props

  return (
    <div
      className={clsx(
        classes.submenu,
        classes.submenu__solutions,
        classes[`submenu${type}`],
      )}
    >
      <div
        className={clsx(
          classes.submenuWrapper,
          classes.submenuWrapper__solutions,
        )}
      >
        <div className={clsx(classes.submenuBlock, classes.submenuBlock__main)}>
          <div className={classes.submenuBlockVisual}>
            <img
              width={49}
              height={105}
              loading='lazy'
              className={classes.submenuBlockIcon}
              src={Icon.src}
              alt='Icon'
            />
          </div>
          <div>
            <span className={classes.submenuBlockCaption}>{name}</span>
            <span className={classes.submenuBlockDescription}>
              You can implement any product integration use case with our
              products and services. This section highlights most common
              examples.
            </span>
          </div>
        </div>
        <div className={clsx(classes.submenuBlock, classes.submenuBlock__info)}>
          <div>
            <span className={classes.submenuBlockCaption}>
              Custom solutions
            </span>
            <span className={classes.submenuBlockDescription}>
              No resources to implement your integrations? <br />
              Our world-class solutions team will take care of your use cases
              end to end.
            </span>
          </div>
          <BookCalendlyButton
            className={clsx(classes.submenuBlockButton)}
            category={'secondary'}
            showIcon={false}
          >
            Talk to Us
          </BookCalendlyButton>
        </div>
        {columns?.map(({ caption, links }, idx) => (
          <div
            className={clsx(
              classes.submenuColumn,
              classes.submenuColumn__solutions,
            )}
            key={idx}
          >
            {caption && (
              <span className={classes.submenuCaption}>{caption}</span>
            )}
            <ul className={classes.submenuList}>
              {links.map(({ Icon, rotated, title, href, isBlank }, idx) => (
                <li className={classes.submenuItem} key={idx}>
                  {Icon && (
                    <Icon
                      className={clsx(
                        classes.icon__solutions,
                        rotated && classes.icon__rotated,
                      )}
                    />
                  )}
                  <Link
                    href={href}
                    target={isBlank ? '_blank' : '_self'}
                    className={classes.submenuLink}
                  >
                    {title}
                    <TbArrowRight
                      className={clsx(classes.icon, classes.icon__arrow)}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

const DefaultSubmenu = ({ ...props }: HeaderNavigationDataType) => {
  const { columns, type } = props

  return (
    <div className={clsx(classes.submenu, classes[`submenu${type}`])}>
      <div className={classes.submenuWrapper}>
        {columns?.map(({ caption, links }, idx) => (
          <div className={classes.submenuColumn} key={idx}>
            {caption && (
              <span className={classes.submenuCaption}>{caption}</span>
            )}
            <ul className={classes.submenuList}>
              {links.map(({ title, href, isBlank }, idx) => (
                <li className={classes.submenuItem} key={idx}>
                  <Link
                    href={href}
                    target={isBlank ? '_blank' : '_self'}
                    className={classes.submenuLink}
                  >
                    {title}
                    <TbArrowRight
                      className={clsx(classes.icon, classes.icon__arrow)}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}
