import ImageMob1x from './image-mob1x.png'
import ImageMob2x from './image-mob2x.png'
import ImageDesk1x from './image-desk1x.png'
import ImageDesk2x from './image-desk2x.png'
import clsx from 'clsx'
import classes from './UniversalIntegrationsVisual.module.scss'

export const UniversalIntegrationsVisual = ({
  bgIsVisible,
  className,
}: {
  bgIsVisible?: boolean
  className?: string
}) => {
  return (
    <div className={classes.visual}>
      {bgIsVisible && (
        <div className={clsx('page__background', classes.background)} />
      )}
      <div className={clsx(classes.wrapper, className)}>
        <picture
          className={clsx(classes.image, bgIsVisible && classes.image__dark)}
        >
          <source
            media='(min-resolution: 2dppx) and (min-width: 768px)'
            srcSet={ImageDesk2x.src}
          />
          <source media='(min-width: 768px)' srcSet={ImageDesk1x.src} />
          <source
            media='(min-resolution: 2dppx) and (max-width: 767px)'
            srcSet={ImageMob2x.src}
          />
          <source media='(max-width: 767px)' srcSet={ImageMob1x.src} />
          <img
            src={ImageMob1x.src}
            alt='Universal Integrations'
            width={350}
            height={928}
            fetchPriority='high'
            loading='eager'
          />
        </picture>
      </div>
    </div>
  )
}
